<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                <div class="vx-logo flex justify-items-center items-center mb-2 pt-4">
                  <img src="@/assets/images/logo/logo.png" alt="logo" class="mx-auto" style="max-height: 25px"/>
                </div>
              <div class="p-4">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4 grid justify-items-center">{{ $t('email_template_types.reset_password') }}</h4>
                  <p>{{ $t('por-favor-informe-seu-email-e-sua-nova-s') }}</p>
                </div>

                <vs-input
                  type="email"
                  label-placeholder="Email"
                  v-validate="'email'"
                  v-model="email"
                  class="w-full"
                  data-vv-as="email"
                  name="email"
                  ref="email"
                />

                <div class="pt-2 pb-2">
                  <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                </div>

                <vs-input
                  type="password"
                  name="password"
                  v-validate="'verify_password'"
                  label-placeholder="Senha"
                  v-model="password"
                  class="w-full"
                  ref="password"
                  data-vv-as="password"
                />
                {{password}}

                <div class="pt-2 pb-2">
                  <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                </div>

                <vs-input
                  type="password"
                  name="password_confirmation"
                  v-validate="'confirmed:password'"
                  label-placeholder="Confirmação Senha"
                  v-model="passwordConfirm"
                  class="w-full"
                  data-vv-as="password"
                />
                
                <div class="pt-2 pb-2">
                  <span class="text-danger text-sm">{{ errors.first('password_confirmation') }}</span>
                </div>

                <div class="flex flex-wrap justify-center sm:flex-row">
                  <vs-button
                    class="w-full sm:w-auto mb-4 mt-4 mr-4 ml-4"
                    :disabled="!validateForm"
                    @click="resetPassword">{{ $t('recuperar') }}</vs-button>
                  <vs-button
                    type="border"
                    to="/login"
                    class="w-full sm:w-auto mb-4 mt-4 mr-4 ml-4 sm:mb-auto sm:mt-auto">{{ $t('common.back') }}</vs-button>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    token: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      email: '',
      password: '',
      passwordConfirm: ''
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== ''
    }
  },
  methods: {
    resetPassword() {
      this.$vs.loading()
      const payload = {
        userDetails: {
          token: this.token,
          email: this.email,
          password: this.password,
          passwordConfirm: this.passwordConfirm
        },
        vs: this.$vs,
        closeAnimation: this.$vs.loading.close
      }
      this.$store.dispatch('auth/resetPassword', payload)
    }
  }
}
</script>
